import { background, Box, Icon, Link, Text, useToast } from '@chakra-ui/react';
import { useWeb3React } from '@web3-react/core';
import { BiCopyAlt } from 'react-icons/bi';
import { getAddressAbbr, getNetworkInfoByChainId } from '../utils';

export const Address = ({ address }) => {
  const { chainId } = useWeb3React();
  const networkInfo = getNetworkInfoByChainId(chainId);
  const blockExplorerUrl = networkInfo.blockExplorerUrls[0];
  const toast = useToast();
  const copyAddress = () => {
    if (!navigator.clipboard) return;
    navigator.clipboard
      .writeText(address)
      .then(() => {
        toast({
          title: 'Address copied.',
          duration: 3000,
          isClosable: true,
        });
        console.log('Address copied to clipboard.');
      })
      .catch(err => {
        console.log('Something went wrong when copying address', err);
      });
  };

  return (
    <Text fontSize="xl" fontWeight="bold">
      <Link href={`${blockExplorerUrl}address/${address}`} isExternal>
        {getAddressAbbr(address)}
      </Link>
      <Icon
        as={BiCopyAlt}
        onClick={copyAddress}
        sx={{ marginLeft: '5px', cursor: 'pointer' }}
      />
    </Text>
  );
};
