export const CONTRACTS = {
  TOKENS: {
    BOBA: {
      boba: {
        address: '0xa18bF3994C0Cc6E3b63ac420308E5383f53120D7',
        abi: require('../abi/@openzeppelin/contracts/token/ERC20/IERC20.sol/IERC20.json'),
      },
      bobarinkeby: {
        address: '0xF5B97a4860c1D81A1e915C40EcCB5E4a5E6b8309',
        abi: require('../abi/@openzeppelin/contracts/token/ERC20/IERC20.sol/IERC20.json'),
      },
    },
  },
  BobaTuringCredit: {
    boba: {
      address: '0xd8E006702bdCbE2582dF13f900bCF750129bB449',
      abi: require('../abi/contracts/BobaTuringCredit.sol/BobaTuringCredit.json'),
    },
    bobarinkeby: {
      address: '0x208c3CE906cd85362bd29467819d3AcbE5FC1614',
      abi: require('../abi/contracts/BobaTuringCredit.sol/BobaTuringCredit.json'),
    },
  },
  TuringSubscriptionManager: {
    boba: require('../deployments/boba/TuringSubscriptionManager.json'),
    bobarinkeby: require('../deployments/bobaRinkeby/TuringSubscriptionManager.json'),
  },
};

export const TOKEN_DECIMALS = {
  BOBA: 18,
};
