import {
  Heading,
  Box,
  Button,
  Flex,
  HStack,
  VStack,
  Input,
  FormControl,
  FormLabel,
  FormHelperText,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Link,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
  Spacer,
} from '@chakra-ui/react';
import { useWeb3React } from '@web3-react/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  createSubscription,
  loadOwnedSubscriptions,
} from '../slices/SubscriptionSlice';
import { getDefaultProvider } from '../utils';

const CREATE_SUBSCRIPTION_TX_ID = 'CREATE_SUBSCRIPTION';

export const CreateSubscriptionModal = ({ onClose }) => {
  const { active, library, chainId, account } = useWeb3React();
  const provider = active ? library.getSigner() : getDefaultProvider();
  const dispatch = useDispatch();

  const dispatchToCreateSubscription = () => {
    dispatch(
      createSubscription({
        provider: provider,
        chainId: chainId,
        dispatch: dispatch,
        txId: CREATE_SUBSCRIPTION_TX_ID,
        onDoneCallback: () => {
          dispatch(
            loadOwnedSubscriptions({
              provider: provider,
              chainId: chainId,
              account: account,
              dispatch: dispatch,
            })
          );
          onClose();
        },
      })
    );
  };
  return (
    <ModalContent
      sx={{
        background:
          'linear-gradient(0deg, rgba(77,22,99,1) 0%, rgba(68,42,157,1) 100%);',
      }}
    >
      <ModalCloseButton />
      <ModalBody>
        <Heading fontSize="xl" sx={{ marginBottom: '10px', marginTop: '10px' }}>
          Create a new Subscription
        </Heading>
        <VStack>
          <Box width="100%">
            <FormControl
              variant="floating"
              id="subscription-address"
              isReadOnly
            >
              <FormLabel>Default owner address</FormLabel>
              <Input
                value={active ? account : 'Connect your wallet!'}
                isInvalid={!active}
              />
              <FormHelperText>
                The owner(s) can edit and cancel the subscription anytime. You
                can add more owners anytime.
              </FormHelperText>
            </FormControl>
          </Box>
        </VStack>
      </ModalBody>
      <ModalFooter>
        <HStack>
          <Button variant="pinkOutline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="pinkSolid"
            onClick={dispatchToCreateSubscription}
            isDisabled={!active}
          >
            Create
          </Button>
        </HStack>
      </ModalFooter>
    </ModalContent>
  );
};

export const MySubscription = () => {
  const { active } = useWeb3React();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const subscriptionIdList = useSelector(
    state => state.subscription.ownedSubscriptionIdList
  );
  const subscriptionInfoMap = useSelector(
    state => state.subscription.subscriptionInfoMap
  );
  const navigate = useNavigate();

  return (
    <VStack
      width="100%"
      sx={{
        border: '1px solid rgb(240, 46, 170 )',
        borderRadius: '10px',
        padding: '20px',
        boxShadow:
          'rgba(240, 46, 170, 0.4) 0px 5px, rgba(240, 46, 170, 0.3) 0px 10px, rgba(240, 46, 170, 0.2) 0px 15px, rgba(240, 46, 170, 0.1) 0px 20px, rgba(240, 46, 170, 0.05) 0px 25px;',
      }}
    >
      <Box sx={{ width: '100%' }}>
        <Flex>
          <Heading
            sx={{
              fontFamily: '"Press Start 2P"',
              textAlign: 'left',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            fontSize="lg"
          >
            My Subscriptions
          </Heading>
          <Spacer />
          <Button disabled={!active} variant="greenOutline" onClick={onOpen}>
            Create Subscription
          </Button>
        </Flex>
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <CreateSubscriptionModal onClose={onClose} />
        </Modal>
      </Box>

      <TableContainer width="100%">
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th isNumeric># of Permitted Callers</Th>
              <Th isNumeric>Credit</Th>
            </Tr>
          </Thead>
          <Tbody fontSize="2xl">
            {subscriptionIdList.map(id => {
              return (
                <Tr key={`subscription-${id}`}>
                  <Td
                    fontFamily='"Press Start 2P"'
                    fontSize="lg"
                    textDecoration="underline"
                  >
                    <Link onClick={() => navigate(`/subscription/${id}`)}>
                      {`#${id}`}
                    </Link>
                  </Td>
                  <Td isNumeric>
                    {subscriptionInfoMap[id] &&
                      subscriptionInfoMap[id].consumerList.length}
                  </Td>
                  <Td isNumeric>
                    {subscriptionInfoMap[id] && subscriptionInfoMap[id].credit}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </VStack>
  );
};
