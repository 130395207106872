import { Container, VStack } from '@chakra-ui/react';
import { useWeb3React } from '@web3-react/core';
import { MySubscription } from './MySubscription';
import { UnsupportedNetworkMessage } from '../components/UnsupportedNetworkMessage';

export const Main = () => {
  const { chainId } = useWeb3React();

  return (
    <Container maxW="container.lg">
      <VStack sx={{ padding: 0 }}>
        {chainId ? <MySubscription /> : <UnsupportedNetworkMessage />}
      </VStack>
    </Container>
  );
};
