import { Box } from '@chakra-ui/layout';
import { useWeb3React } from '@web3-react/core';
import { getNetworkInfoByChainId } from '../utils';

export const CurrentNetwork = () => {
  const { chainId } = useWeb3React();
  const networkInfo = getNetworkInfoByChainId(chainId);

  return (
    <Box
      sx={{
        padding: '5px 10px',
        borderRadius: '5px',
        border: '1px solid rgb(240, 46, 170 )',
      }}
    >
      {chainId ? networkInfo.chainName : 'Unsupported Network'}
    </Box>
  );
};
