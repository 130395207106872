import React from 'react';
import { ChakraProvider, VStack, Container, Box } from '@chakra-ui/react';
import { ethers } from 'ethers';
import { batch, Provider, useDispatch } from 'react-redux';
import store from './store';
import { useWeb3React, Web3ReactProvider } from '@web3-react/core';
import { Main } from './views/Main';
import { theme } from './theme';
import { BrowserRouter, Route, Routes, Outlet } from 'react-router-dom';
import { Subscription } from './views/Subscription';
import './App.css';
import { Header } from './views/Header';
import {
  loadBobaAllowance,
  loadOwnedSubscriptions,
  loadTuringPrice,
} from './slices/SubscriptionSlice';
import { loadAccountBalance } from './slices/AccountSlice';
import { getDefaultProvider } from './utils';

const updatePublicState = (provider, chainId, dispatch) => {
  batch(() => {
    dispatch(
      loadTuringPrice({
        provider: provider,
        chainId: chainId,
      })
    );
  });
};

const updateAccountState = (provider, chainId, account, dispatch) => {
  batch(() => {
    dispatch(
      loadAccountBalance({
        provider: provider,
        chainId: chainId,
        account: account,
      })
    );
    dispatch(
      loadOwnedSubscriptions({
        provider: provider,
        chainId: chainId,
        account: account,
        dispatch: dispatch,
      })
    );
    dispatch(
      loadBobaAllowance({
        provider: provider,
        chainId: chainId,
        account: account,
      })
    );
  });
};

const Footer = () => {
  return <Container maxW="container.lg"></Container>;
};

const Announcement = () => {

return (
  <Box
    sx={{
      width: '100%',
      backgroundColor: 'black',
      textAlign: 'center',
      padding: 1,
    }}
  >
    🎊🎊🎊 Congrats!!! TuringHub is the 1st prize winner on Boba Network Global
    Hackthon (IRL/Turing category)! 🎊🎊🎊
  </Box>
);
}

function getLibrary(provider, connector) {
  return new ethers.providers.Web3Provider(provider);
}

const MainUi = () => {
  const { account, active, library, chainId } = useWeb3React();
  const provider = active ? library.getSigner() : getDefaultProvider();
  const dispatch = useDispatch();
  updatePublicState(provider, chainId, dispatch);
  updateAccountState(provider, chainId, account, dispatch);
  return (
    <VStack sx={{ width: '100%', height: '100vh' }}>
      <Announcement />
      <Header />
      <Outlet />
      <Footer />
    </VStack>
  );
};

const App = () => {
  return (
    <Provider store={store}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <ChakraProvider resetCSS theme={theme}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<MainUi />}>
                <Route index element={<Main />} />
                <Route
                  path="subscription/:subscriptionId"
                  element={<Subscription />}
                />
              </Route>
            </Routes>
          </BrowserRouter>
        </ChakraProvider>
      </Web3ReactProvider>
    </Provider>
  );
};

export default App;
