import { extendTheme } from '@chakra-ui/react';
export const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: 'linear-gradient(0deg, rgba(11,0,20,1) 0%, rgba(22,0,40,1) 80%, rgba(34,2,61,1) 100%);',
        color: 'white',
      },
    },
  },
  colors: {
    pink: 'rgb(227, 107, 144)',
  },
  components: {
    Button: {
      variants: {
        pinkSolid: {
          background: 'rgb(227, 107, 144)',
          color: 'white',
          _hover: {
            backgroundColor: 'rgb(181, 85, 115)',
          },
        },
        pinkOutline: {
          border: '1px solid rgb(227, 107, 144)',
          color: 'rgb(227, 107, 144)',
          _hover: {
            background: 'rgb(227, 107, 144)',
            color: 'white',
          },
        },
        greenSolid: {
          background: 'rgb(106, 207, 127)',
          color: 'white',
          _hover: {
            background: 'rgb(80, 161, 97)',
          },
        },
        greenOutline: {
          border: '1px solid rgb(106, 207, 127)',
          color: 'rgb(106, 207, 127)',
          _hover: {
            background: 'rgb(106, 207, 127)',
            color: 'white',
          },
        },
      },
    },
  },
});
