import { createSlice } from "@reduxjs/toolkit";
import { error, info } from "./MessageSlice";

export const pendingTxSlice = createSlice({
  name: "pendingTransactions",
  /**
   * transaction object
   * @typedef {Object} Transaction
   * @property {string} txHash - hash string of the transaction
   * @property {string} txId - ID of the tx for checking the status of the tx.
   * @property {string} txState - State of the tx .
   * @property {string} errorMsg - ID of the tx for checking the status of the tx.
   */

  /** @type {Transaction[]} */
  initialState: [],
  reducers: {
    pushPendingTx: (state, action) => {
      state.push(action.payload);
    },
    clearPendingTx: (state, action) => {
      const target = state.find((x) => x.txHash === action.payload);
      target && state.splice(state.indexOf(target), 1);
      return state;
    },
    clearEmptyTx: (state, action) => {
      // clear tx with empty tx hash
      state = state.filter((tx) => tx.txHash !== "");
      return state;
    },
    updateTxState: (state, action) => {
      const target = state.find((x) => x.txHash === action.payload.txHash);
      target.txState = action.payload.txState;
      return state;
    },
  },
});

export const isPendingTx = (pendingTransactions, txId) => {
  return pendingTransactions.map((x) => x.txId).includes(txId);
};

export const getPendingTxByTxId = (pendingTransactions, txId) => {
  return pendingTransactions.filter((tx) => tx.txId === txId);
};

export const executeTransaction = async ({
  transactionCall,
  txId,
  onSucceededMsg,
  onDoneCallback,
  dispatch,
}) => {
  let tx = null;
  try {
    tx = await transactionCall();
    dispatch(info(`Tx: ${tx.hash}`));
    dispatch(
      pushPendingTx({ txHash: tx.hash, txId: txId, txState: "pending" })
    );

    await tx.wait();
    dispatch(updateTxState({ txHash: tx.hash, txState: 'success' }));
    dispatch(info(onSucceededMsg));
    onDoneCallback && onDoneCallback();
  } catch (e) {
    if (!tx) {
      dispatch(pushPendingTx({ txId: txId, txState: "failed", txHash: "", errorMsg: e.message }));
    } else {
      dispatch(updateTxState({ txHash: tx.hash, txState: "failed", errorMsg: e.message }));
    }
    dispatch(error(e.message));
    return;
  } finally {
    tx && dispatch(clearPendingTx(tx.hash));
    dispatch(clearEmptyTx());
  }
};

export const { pushPendingTx, clearPendingTx, updateTxState, clearEmptyTx } =
  pendingTxSlice.actions;
export default pendingTxSlice.reducer;
