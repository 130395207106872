import { Image } from '@chakra-ui/react';
import blockies from 'blockies-identicon';

export const Blockie = ({ address, size = 8, style }) => {
  const icon = blockies.create({
    seed: address,
    size: size,
  });
  return (
    <Image
      sx={{ borderRadius: 'var(--chakra-radii-md)', ...style }}
      src={icon.toDataURL()}
    />
  );
};
