import {
  Link,
  Box,
  Heading,
  Container,
  Spacer,
  HStack,
  Text,
  Icon,
} from '@chakra-ui/react';
import { Wallet } from '../components/Wallet';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { uintToDecimalAmount } from '../utils';
import { CurrentNetwork } from '../components/CurrentNetwork';
import { BsQuestionLg } from 'react-icons/bs';

export const Header = () => {
  const navigate = useNavigate();
  const turingPrice = useSelector(state => state.subscription.turingPrice);
  return (
    <Container
      sx={{ marginTop: '10px', marginBottom: '10px' }}
      maxW="container.lg"
    >
      <HStack>
        <Link onClick={() => navigate('/')}>
          <Heading
            sx={{
              textAlign: 'left',
              fontFamily: '"Press Start 2P"',
              color: 'rgb(106, 207, 127)',
            }}
            fontSize="xl"
          >
            Turing Hub
          </Heading>
        </Link>
        <Spacer />
        <Box
          sx={{
            padding: '5px 10px',
            borderRadius: '5px',
            border: '1px solid rgb(240, 46, 170 )',
            cursor: 'pointer',
          }}
          onClick={() =>
            window
              .open(
                'https://github.com/medieval-dao/turing-subscription/blob/main/README.md',
                '_blank'
              )
              .focus()
          }
        >
          <Icon as={BsQuestionLg} />
        </Box>
        <Box
          sx={{
            padding: '5px 10px',
            borderRadius: '5px',
            border: '1px solid rgb(240, 46, 170 )',
          }}
        >
          <Text>{`Turing $: ${uintToDecimalAmount(
            'BOBA',
            turingPrice
          )} BOBA`}</Text>
        </Box>
        <CurrentNetwork />
        <Wallet />
      </HStack>
    </Container>
  );
};
