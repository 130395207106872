import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { constants, Contract } from "ethers";
import { chainIdToNetworkKeyName } from "../utils";
import { CONTRACTS } from "../constants/Contracts";

export const loadAccountBalance = createAsyncThunk(
  "account/loadAccountBalance",
  async ({ provider, account, chainId }) => {
    const chainKeyName = chainIdToNetworkKeyName(chainId);

    const ethBalance = provider.getBalance("ethers.eth").toString();

    const tokenList = Object.keys(CONTRACTS.TOKENS).filter(
      (token) => !token.endsWith("Proxy")
    );
    const balances = (
      await Promise.all(
        tokenList.map(async (token) => {
          const contract = new Contract( CONTRACTS.TOKENS[token][chainKeyName].address,
            CONTRACTS.TOKENS[token][chainKeyName].abi,
            provider
          );

          const balance = await contract.balanceOf(account);
          return { token: token, balance: balance.toString() };
        })
      )
    ).reduce((acc, x) => {
      acc[x.token] = x.balance;
      return acc;
    }, {});

    balances["ETH"] = ethBalance;
    return balances;
  }
);

const accountSlice = createSlice({
  name: "account",
  initialState: {
    isLoading: false,
    balance: {
      ETH: constants.Zero.toString(),
      BOBA: constants.Zero.toString(),
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadAccountBalance.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loadAccountBalance.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.balance = action.payload;
        }
        return state;
      })
      .addCase(loadAccountBalance.rejected, (state, { error }) => {
        state.isLoading = false;
      });
  },
});

const accountState = (state) => state.account;

export default accountSlice.reducer;
export const { updateAccountBalance } = accountSlice.actions;
export const getAccountState = createSelector(
  accountState,
  (account) => account
);
