import { NETWORKS, DEFAULT_CHAIN_ID } from './constants/Networks';
import { utils, providers } from 'ethers';
import { TOKEN_DECIMALS } from './constants/Contracts';

export const isDevelopment = () => {
  return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
};

export const switchNetwork = async networkKeyName => {
  // remove `keyName` to avoid metamask to reject the request.
  const { keyName, ...networkInfoForMetaMask } = NETWORKS[networkKeyName];
  try {
    if (!window.ethereum) throw new Error('No crypto wallet found');
    await window.ethereum.request({
      id: 1,
      jsonrpc: '2.0',
      method: 'wallet_addEthereumChain',
      params: [networkInfoForMetaMask],
    });
  } catch (err) {
    console.log(err);

    // Try to add a default network of MetaMask, change to switch network request
    if (err.code === -32602) {
      try {
        await window.ethereum.request({
          id: 1,
          jsonrpc: '2.0',
          method: 'wallet_switchEthereumChain',
          params: [
            {
              chainId: networkInfoForMetaMask.chainId,
            },
          ],
        });
      } catch (err) {
        console.log(err);
      }
    }
  }
};

export const getNetworkInfoByChainId = chainId => {
  if (!chainId) chainId = DEFAULT_CHAIN_ID;
  for (let network in NETWORKS) {
    if (NETWORKS[network].chainId === `0x${Number(chainId).toString(16)}`)
      return NETWORKS[network];
  }
  return null;
};

export const chainIdToNetworkKeyName = chainId => {
  const networkInfo = chainId
    ? getNetworkInfoByChainId(chainId)
    : getNetworkInfoByChainId(DEFAULT_CHAIN_ID);
  return networkInfo.keyName;
};

export const getDefaultNetworkInfo = (isTestnet = false) => {
  return isTestnet ? NETWORKS.bobarinkeby : NETWORKS.boba;
};

export const getDefaultProvider = (isTestnet = false) => {
  const networkInfo = getDefaultNetworkInfo(isTestnet);
  return new providers.JsonRpcBatchProvider(networkInfo.rpcUrls[0]);
};

export const normalizeInputNumberString = valueString => {
  valueString = valueString.replace(/^0+/, '');
  if (valueString === '') {
    return '0';
  }
  if (valueString.startsWith('.')) {
    return '0' + valueString;
  }
  return valueString;
};

const getDecimals = token => {
  return TOKEN_DECIMALS[token];
};

export const uintToDecimalAmount = (token, amount) => {
  if (!(amount instanceof String)) amount = amount.toString();
  const value = utils.formatUnits(amount, getDecimals(token));
  return value.endsWith('.0') ? value.replace('.0', '') : value;
};

export const decimalToUintAmount = (token, amount) => {
  if (!(amount instanceof String)) amount = amount.toString();
  return utils.parseUnits(amount, getDecimals(token));
};

export const getAddressAbbr = address => {
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
};
