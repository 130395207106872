import { createSlice } from "@reduxjs/toolkit";

let messageId = 0;

const createMessage = (state, severity, text) => {
  const message = {
    id: messageId++,
    severity: severity,
    text: text,
    timestamp: Date.now(),
  };
  state.messages.push(message);
  console.log(`${message.timestamp} | ${message.severity} | ${message.text}`);
};

const messageSlice = createSlice({
  name: "message",
  initialState: {
    /**
     * @typedef Message
     * @property {number} id - unique ID of the message
     * @property {string} severity - severity like "error", "info", "warning"
     * @property {string} text - message content
     * @property {number} timestamp - timestamp when the message created
     */

    /** @type {Message[]} */
    messages: [],
  },
  reducers: {
    error: (state, action) => {
      createMessage(state, "error", action.payload);
    },
    info: (state, action) => {
      createMessage(state, "info", action.payload);
    },
  },
});

export const { error, info } = messageSlice.actions;
export default messageSlice.reducer;
