import { configureStore } from '@reduxjs/toolkit';
import accountReducer from './slices/AccountSlice';
import messageReducer from './slices/MessageSlice';
import pendingReducer from './slices/PendingTxSlice';
import SubscriptionReducer from './slices/SubscriptionSlice';
import { createLogger } from 'redux-logger';
import { isDevelopment } from './utils';

const loggerMiddleware = createLogger();

export default configureStore({
  reducer: {
    account: accountReducer,
    messages: messageReducer,
    pendingTransactions: pendingReducer,
    subscription: SubscriptionReducer,
  },
  middleware: getDefaultMiddleware => {
    let middleware = getDefaultMiddleware();
    if (isDevelopment()) {
      middleware = middleware.concat(loggerMiddleware);
    }
    return middleware;
  },
});
