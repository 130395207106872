import {
  Button,
  Box,
  Container,
  Text,
  Divider,
  Heading,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  VStack,
  Stat,
  StatNumber,
  StatLabel,
  TableContainer,
  Table,
  Thead,
  Tr,
  Td,
  Th,
  Tbody,
  FormControl,
  FormLabel,
  FormHelperText,
  Icon,
  Modal,
  useDisclosure,
  ModalContent,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  HStack,
  Stack,
  IconButton,
  ModalOverlay,
  ModalHeader,
} from '@chakra-ui/react';
import { useParams } from 'react-router';
import { GiBoba, GiCancel } from 'react-icons/gi';
import { BsTrash, BsFillPersonPlusFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import {
  addBalance,
  addConsumer,
  addOwner,
  cancelSubscription,
  loadBobaAllowance,
  loadSubscriptionInfo,
  removeConsumer,
  removeOwner,
  setApprovalForBoba,
} from '../slices/SubscriptionSlice';
import {
  decimalToUintAmount,
  getDefaultProvider,
  normalizeInputNumberString,
  uintToDecimalAmount,
} from '../utils';
import { useWeb3React } from '@web3-react/core';
import { useEffect, useMemo, useState } from 'react';
import { BigNumber, constants } from 'ethers';
import { Address } from '../components/Address';
import { UnsupportedNetworkMessage } from '../components/UnsupportedNetworkMessage';
import { Blockie } from '../components/Blockie';

export const AddOwnerModal = ({ onClose, subscriptionId }) => {
  const { active, library, chainId } = useWeb3React();
  const provider = active ? library.getSigner() : getDefaultProvider();
  const dispatch = useDispatch();
  const [ownerAddress, setOwnerAddress] = useState('');

  const ADD_OWNER_TX_ID = 'ADD_OWNER_' + ownerAddress;

  const dispatchToAddOwner = () => {
    dispatch(
      addOwner({
        provider: provider,
        chainId: chainId,
        dispatch: dispatch,
        subscriptionId: subscriptionId,
        ownerAddress: ownerAddress,
        txId: ADD_OWNER_TX_ID,
        onDoneCallback: () => {
          dispatch(
            loadSubscriptionInfo({
              provider: provider,
              chainId: chainId,
              subscriptionId: subscriptionId,
            })
          );
          onClose();
        },
      })
    );
  };
  return (
    <ModalContent
      sx={{
        background:
          'linear-gradient(0deg, rgba(77,22,99,1) 0%, rgba(68,42,157,1) 100%);',
      }}
    >
      <ModalCloseButton />
      <ModalHeader>Add a new owner</ModalHeader>
      <ModalBody>
        <FormControl variant="floating" id="owner-address" isRequired>
          <FormLabel>New owner address</FormLabel>
          <Input
            value={ownerAddress}
            onChange={ev => setOwnerAddress(ev.target.value)}
          />
          <FormHelperText>
            The owner(s) can edit and cancel the subscription anytime, including
            removing you from the owner list.
          </FormHelperText>
        </FormControl>
      </ModalBody>

      <ModalFooter>
        <HStack>
          <Button variant="pinkOutline" colorScheme="blue" onClick={onClose}>
            Close
          </Button>
          <Button variant="pinkSolid" onClick={dispatchToAddOwner}>
            Add owner
          </Button>
        </HStack>
      </ModalFooter>
    </ModalContent>
  );
};

export const AddConsumerModal = ({ onClose, subscriptionId }) => {
  const { active, library, chainId } = useWeb3React();
  const provider = active ? library.getSigner() : getDefaultProvider();
  const dispatch = useDispatch();
  const [consumerAddress, setConsumerAddress] = useState('');

  const ADD_CONSUMER_TX_ID = 'ADD_CONSUMER_' + consumerAddress;

  const dispatchToAddConsumer = () => {
    dispatch(
      addConsumer({
        provider: provider,
        chainId: chainId,
        dispatch: dispatch,
        subscriptionId: subscriptionId,
        consumerAddress: consumerAddress,
        txId: ADD_CONSUMER_TX_ID,
        onDoneCallback: () => {
          dispatch(
            loadSubscriptionInfo({
              provider: provider,
              chainId: chainId,
              subscriptionId: subscriptionId,
            })
          );
          onClose();
        },
      })
    );
  };
  return (
    <ModalContent
      sx={{
        background:
          'linear-gradient(0deg, rgba(77,22,99,1) 0%, rgba(68,42,157,1) 100%);',
      }}
    >
      <ModalCloseButton />
      <ModalHeader>Add a new permitted caller</ModalHeader>
      <ModalBody>
        <FormControl variant="floating" id="caller-address" isRequired>
          <FormLabel>New permitted caller address</FormLabel>
          <Input
            value={consumerAddress}
            onChange={ev => setConsumerAddress(ev.target.value)}
          />
          <FormHelperText>
            The permitted caller can spend the turing credit owned by this
            subscription.
          </FormHelperText>
        </FormControl>
      </ModalBody>

      <ModalFooter>
        <HStack>
          <Button variant="pinkOutline" colorScheme="blue" onClick={onClose}>
            Close
          </Button>
          <Button variant="pinkSolid" onClick={dispatchToAddConsumer}>
            Add Permitted Caller
          </Button>
        </HStack>
      </ModalFooter>
    </ModalContent>
  );
};

export const AddBobaBalance = ({ onClose, subscriptionId }) => {
  const { active, library, chainId, account } = useWeb3React();
  const provider = active ? library.getSigner() : getDefaultProvider();
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('0');
  const allowance = useSelector(state => state.subscription.allowance);
  const bobaBalance = useSelector(state => state.account.balance.BOBA);

  const onInputValueChange = ev => {
    const value = ev.target.value;
    const normalizedValue = normalizeInputNumberString(value);
    setInputValue(normalizedValue);
  };

  const ADD_BALANCE_TX_ID = 'ADD_BALANCE';
  const SET_APPROVAL_TX_ID = 'SET_APPROVAL';

  const dispatchToSetApproval = enabled => {
    dispatch(
      setApprovalForBoba({
        provider: provider,
        chainId: chainId,
        enabled: enabled,
        txId: SET_APPROVAL_TX_ID,
        dispatch: dispatch,
        onDoneCallback: () => {
          dispatch(
            loadBobaAllowance({
              provider: provider,
              chainId: chainId,
              account: account,
            })
          );
        },
      })
    );
  };

  const dispatchToAddBalance = () => {
    dispatch(
      addBalance({
        provider: provider,
        chainId: chainId,
        subscriptionId: subscriptionId,
        bobaAmount: decimalToUintAmount('BOBA', inputValue),
        dispatch: dispatch,
        txId: ADD_BALANCE_TX_ID,
        onDoneCallback: () => {
          dispatch(
            loadSubscriptionInfo({
              provider: provider,
              chainId: chainId,
              subscriptionId: subscriptionId,
            })
          );
          onClose();
        },
      })
    );
  };

  const approveButton = (
    <Button variant="pinkSolid" onClick={() => dispatchToSetApproval(true)}>
      Approve
    </Button>
  );
  const unapproveButton = (
    <Button variant="pinkSolid" onClick={() => dispatchToSetApproval(false)}>
      Unapprove
    </Button>
  );
  const topUpButton = (
    <Button variant="pinkSolid" onClick={dispatchToAddBalance}>
      Top Up
    </Button>
  );

  return (
    <ModalContent
      sx={{
        background:
          'linear-gradient(0deg, rgba(77,22,99,1) 0%, rgba(68,42,157,1) 100%);',
      }}
    >
      <ModalHeader>Charge Turing Credit with $BOBA</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <VStack>
          <Text
            fontSize="sm"
            width="100%"
            textAlign="right"
          >{`Available: ${uintToDecimalAmount(
            'BOBA',
            bobaBalance
          )} BOBA`}</Text>
          <Input value={inputValue} onChange={onInputValueChange} />
        </VStack>
      </ModalBody>

      <ModalFooter>
        <HStack>
          <Button variant="pinkOutline" colorScheme="blue" onClick={onClose}>
            Close
          </Button>
          {allowance === constants.Zero.toString()
            ? approveButton
            : topUpButton}
        </HStack>
      </ModalFooter>
    </ModalContent>
  );
};

const SubscriptionState = ({ subscriptionId, subscriptionInfo, isOwner }) => {
  const { active, library, chainId } = useWeb3React();
  const provider = active ? library.getSigner() : getDefaultProvider();
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const turingPrice = useSelector(state => state.subscription.turingPrice);
  const bobaBalance = subscriptionInfo
    ? uintToDecimalAmount(
        'BOBA',
        BigNumber.from(subscriptionInfo.credit).mul(turingPrice)
      )
    : 0;

  const CANCEL_SUBSCRIPTION_TX_ID = 'CANCEL_SUBSCRIPTION_' + subscriptionId;

  const dispatchToCancelSubscription = () => {
    dispatch(
      cancelSubscription({
        provider: provider,
        chainId: chainId,
        subscriptionId: subscriptionId,
        txId: CANCEL_SUBSCRIPTION_TX_ID,
      })
    );
  };

  return (
    <Box width="100%">
      <Box sx={{ padding: '10px' }}>
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <AddBobaBalance onClose={onClose} subscriptionId={subscriptionId} />
        </Modal>
        <VStack>
          <HStack>
            <Stat width="200px">
              <StatLabel>BOBA Balance</StatLabel>
              <StatNumber>{`${bobaBalance} BOBA`}</StatNumber>
            </Stat>

            <Stat width="200px">
              <StatLabel>Turing Credit</StatLabel>
              <StatNumber>
                {subscriptionInfo && subscriptionInfo.credit}
              </StatNumber>
            </Stat>
            <Button
              width="200px"
              leftIcon={<Icon as={GiBoba} />}
              onClick={onOpen}
              variant="greenOutline"
            >
              Top up BOBA
            </Button>
          </HStack>
          <HStack>
            <Stat width="200px">
              <StatLabel>Helper Address</StatLabel>
              <StatNumber>
                {subscriptionInfo && (
                  <Address address={subscriptionInfo.helperAddress} />
                )}
              </StatNumber>
            </Stat>
            <Box width="200px"></Box>
            <Button
              width="200px"
              leftIcon={<Icon as={GiCancel} />}
              onClick={dispatchToCancelSubscription}
              variant="pinkOutline"
              isDisabled={!isOwner}
            >
              Cancel Subscription
            </Button>
          </HStack>
        </VStack>
      </Box>
    </Box>
  );
};

const Owners = ({ subscriptionId, subscriptionInfo, isOwner }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { active, library, chainId } = useWeb3React();
  const provider = active ? library.getSigner() : getDefaultProvider();
  const dispatch = useDispatch();

  const REMOVE_OWNER_TX_ID_PREFIX = 'REMOVE_OWNER_';
  const dispatchToRemoveOwner = (ownerAddress, txId) => {
    dispatch(
      removeOwner({
        provider: provider,
        chainId: chainId,
        dispatch: dispatch,
        subscriptionId: subscriptionId,
        ownerAddress: ownerAddress,
        txId: txId,
        onDoneCallback: () => {
          dispatch(
            loadSubscriptionInfo({
              provider: provider,
              chainId: chainId,
              subscriptionId: subscriptionId,
            })
          );
        },
      })
    );
  };

  return (
    <VStack
      width="100%"
      sx={{
        padding: '20px',
        border: '1px solid rgb(240, 46, 170 )',
        borderRadius: '10px',
        boxShadow:
          'rgba(240, 46, 170, 0.4) 0px 5px, rgba(240, 46, 170, 0.3) 0px 10px, rgba(240, 46, 170, 0.2) 0px 15px, rgba(240, 46, 170, 0.1) 0px 20px, rgba(240, 46, 170, 0.05) 0px 25px;',
      }}
    >
      <Box width="100%">
        <Heading
          sx={{
            fontFamily: '"Press Start 2P"',
            textAlign: 'left',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          fontSize="lg"
        >
          Owners
        </Heading>
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <AddOwnerModal onClose={onClose} subscriptionId={subscriptionId} />
        </Modal>
      </Box>
      <TableContainer width="100%">
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Address</Th>
              <Th textAlign="right">
                {isOwner && (
                  <IconButton
                    aria-label="Add Owner"
                    icon={<Icon as={BsFillPersonPlusFill} />}
                    variant="greenOutline"
                    onClick={onOpen}
                  />
                )}
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {subscriptionInfo &&
              subscriptionInfo.ownerList.map((ownerAddress, idx) => {
                return (
                  <Tr key={`owner-${idx}`}>
                    <Td>
                      <HStack>
                        <Blockie address={ownerAddress} />
                        <Address address={ownerAddress} />
                      </HStack>
                    </Td>
                    <Td textAlign="right">
                      {isOwner && (
                        <IconButton
                          aria-label="Delete Owner"
                          icon={<Icon as={BsTrash} />}
                          variant="pinkOutline"
                          onClick={() => {
                            dispatchToRemoveOwner(
                              ownerAddress,
                              REMOVE_OWNER_TX_ID_PREFIX + ownerAddress
                            );
                          }}
                        />
                      )}
                    </Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
    </VStack>
  );
};

const Consumers = ({ subscriptionId, subscriptionInfo, isOwner }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { active, library, chainId } = useWeb3React();
  const provider = active ? library.getSigner() : getDefaultProvider();
  const dispatch = useDispatch();

  const REMOVE_CONSUMER_TX_ID_PREFIX = 'REMOVE_CONSUMER_';
  const dispatchToRemoveConsumer = (consumerAddress, txId) => {
    dispatch(
      removeConsumer({
        provider: provider,
        chainId: chainId,
        dispatch: dispatch,
        subscriptionId: subscriptionId,
        consumerAddress: consumerAddress,
        txId: txId,
        onDoneCallback: () => {
          dispatch(
            loadSubscriptionInfo({
              provider: provider,
              chainId: chainId,
              subscriptionId: subscriptionId,
            })
          );
        },
      })
    );
  };

  return (
    <VStack
      width="100%"
      sx={{
        padding: '20px',
        border: '1px solid rgb(240, 46, 170 )',
        borderRadius: '10px',
        boxShadow:
          'rgba(240, 46, 170, 0.4) 0px 5px, rgba(240, 46, 170, 0.3) 0px 10px, rgba(240, 46, 170, 0.2) 0px 15px, rgba(240, 46, 170, 0.1) 0px 20px, rgba(240, 46, 170, 0.05) 0px 25px;',
      }}
    >
      <Box width="100%">
        <Heading
          sx={{
            fontFamily: '"Press Start 2P"',
            textAlign: 'left',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          fontSize="lg"
        >
          Permitted Callers
        </Heading>
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <AddConsumerModal onClose={onClose} subscriptionId={subscriptionId} />
        </Modal>
      </Box>
      <TableContainer width="100%">
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Address</Th>
              <Th textAlign="right">
                {isOwner && (
                  <IconButton
                    aria-label="Add Owner"
                    icon={<Icon as={BsFillPersonPlusFill} />}
                    variant="greenOutline"
                    onClick={onOpen}
                  />
                )}
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {subscriptionInfo &&
              subscriptionInfo.consumerList.map((consumerAddress, idx) => {
                return (
                  <Tr key={`consumer-${idx}`}>
                    <Td>
                      <HStack>
                        <Blockie address={consumerAddress} />
                        <Address address={consumerAddress} />
                      </HStack>
                    </Td>
                    <Td textAlign="right">
                      {isOwner && (
                        <IconButton
                          aria-label="Delete Consumer"
                          icon={<Icon as={BsTrash} />}
                          variant="pinkOutline"
                          onClick={() => {
                            dispatchToRemoveConsumer(
                              consumerAddress,
                              REMOVE_CONSUMER_TX_ID_PREFIX + consumerAddress
                            );
                          }}
                        />
                      )}
                    </Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
    </VStack>
  );
};

const UsageTabContent = ({ subscriptionId }) => {
  return (
    <TableContainer width="100%">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Time</Th>
            <Th>Consumer</Th>
            <Th>Transaction Hash</Th>
            <Th>Spent</Th>
            <Th>Balance</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>
              <Box>March 22, 2022, 17:50 UTC</Box>
              <Box>
                <a href="123">Block#2321792</a>
              </Box>
            </Td>
            <Td>0x237409234789237483278</Td>
            <Td>
              <a href="221">0x2429348230948932</a>
            </Td>
            <Td>0.01 BOBA</Td>
            <Td>1000.01 BOBA</Td>
          </Tr>
          <Tr>
            <Td>
              <Box>March 22, 2022, 17:50 UTC</Box>
              <Box>
                <a href="123">Block#2321792</a>
              </Box>
            </Td>
            <Td>0x237409234789237483278</Td>
            <Td>
              <a href="221">0x2429348230948932</a>
            </Td>
            <Td>0.01 BOBA</Td>
            <Td>1000.01 BOBA</Td>
          </Tr>
          <Tr>
            <Td>
              <Box>March 22, 2022, 17:50 UTC</Box>
              <Box>
                <a href="123">Block#2321792</a>
              </Box>
            </Td>
            <Td>0x237409234789237483278</Td>
            <Td>
              <a href="221">0x2429348230948932</a>
            </Td>
            <Td>0.01 BOBA</Td>
            <Td>1000.01 BOBA</Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};

const EventsTabContent = ({ subscriptionId }) => {
  return (
    <TableContainer width="100%">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Time</Th>
            <Th>Event</Th>
            <Th>Transaction Hash</Th>
            <Th>Consumer</Th>
            <Th>Amount</Th>
            <Th>Balance</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>
              <Box>March 22, 2022, 17:50 UTC</Box>
              <Box>
                <a href="123">Block#2321792</a>
              </Box>
            </Td>
            <Td>Subscription Funded</Td>
            <Td>
              <a href="221">0x2429348230948932</a>
            </Td>
            <Td>0x238449038093249032</Td>
            <Td>0.01 BOBA</Td>
            <Td>1000.01 BOBA</Td>
          </Tr>
          <Tr>
            <Td>
              <Box>March 22, 2022, 17:50 UTC</Box>
              <Box>
                <a href="123">Block#2321792</a>
              </Box>
            </Td>
            <Td>Subscription Funded</Td>
            <Td>
              <a href="221">0x2429348230948932</a>
            </Td>
            <Td>0x238449038093249032</Td>
            <Td>0.01 BOBA</Td>
            <Td>1000.01 BOBA</Td>
          </Tr>
          <Tr>
            <Td>
              <Box>March 22, 2022, 17:50 UTC</Box>
              <Box>
                <a href="123">Block#2321792</a>
              </Box>
            </Td>
            <Td>Subscription Funded</Td>
            <Td>
              <a href="221">0x2429348230948932</a>
            </Td>
            <Td>0x238449038093249032</Td>
            <Td>0.01 BOBA</Td>
            <Td>1000.01 BOBA</Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};

const History = ({ subscriptionId }) => {
  return (
    <VStack width="100%">
      <Box width="100%">
        <Heading sx={{ textAlign: 'left' }}>History</Heading>
      </Box>
      <Tabs width="100%">
        <TabList>
          <Tab>Usage</Tab>
          <Tab>Events</Tab>
        </TabList>

        <TabPanels>
          <TabPanel sx={{ paddingLeft: 0, paddingRight: 0 }}>
            <UsageTabContent subscriptionId={subscriptionId} />
          </TabPanel>
          <TabPanel sx={{ paddingLeft: 0, paddingRight: 0 }}>
            <EventsTabContent subscriptionId={subscriptionId} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );
};

export const Subscription = () => {
  const params = useParams();
  const { active, library, chainId } = useWeb3React();
  const provider = active ? library.getSigner() : getDefaultProvider();
  const subscriptionId = params.subscriptionId;
  const subscriptionInfoMap = useSelector(
    state => state.subscription.subscriptionInfoMap
  );
  const ownedSubscriptionIdList = useSelector(
    state => state.subscription.ownedSubscriptionIdList
  );
  const dispatch = useDispatch();

  const isOwner = useMemo(() => {
    return ownedSubscriptionIdList.includes(parseInt(subscriptionId));
  }, [ownedSubscriptionIdList, subscriptionId]);

  useEffect(() => {
    chainId &&
      dispatch(
        loadSubscriptionInfo({
          provider: provider,
          chainId: chainId,
          subscriptionId: subscriptionId,
        })
      );
  }, [subscriptionId, dispatch, chainId, provider]);

  if (!chainId) {
    return (
      <Container maxW="container.lg">
        <UnsupportedNetworkMessage />
      </Container>
    );
  }

  return (
    <Container maxW="container.lg">
      <VStack width="100%">
        <Heading
          sx={{
            fontFamily: '"Press Start 2P"',
            textAlign: 'left',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          fontSize="2xl"
        >{`Subscription #${subscriptionId}`}</Heading>
        <Stack direction="row" width="100%">
          <SubscriptionState
            subscriptionId={subscriptionId}
            subscriptionInfo={subscriptionInfoMap[subscriptionId]}
            isOwner={isOwner}
          />
        </Stack>
        <Divider />
        <Stack width="100%" direction="row">
          <Owners
            subscriptionId={subscriptionId}
            subscriptionInfo={subscriptionInfoMap[subscriptionId]}
            isOwner={isOwner}
          />

          <Consumers
            subscriptionId={subscriptionId}
            subscriptionInfo={subscriptionInfoMap[subscriptionId]}
            isOwner={isOwner}
          />
        </Stack>
        {/* <History subscriptionId={subscriptionId} /> */}
      </VStack>
    </Container>
  );
};
