import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { constants, Contract, BigNumber } from 'ethers';
import { CONTRACTS } from '../constants/Contracts';
import { chainIdToNetworkKeyName } from '../utils';
import { executeTransaction } from './PendingTxSlice';

const getSubscriptionContract = (chainId, provider) => {
  const chainKeyName = chainIdToNetworkKeyName(chainId);
  const subscriptionContract = new Contract(
    CONTRACTS.TuringSubscriptionManager[chainKeyName].address,
    CONTRACTS.TuringSubscriptionManager[chainKeyName].abi,
    provider
  );
  return subscriptionContract;
};

const getBobaContract = (chainId, provider) => {
  const chainKeyName = chainIdToNetworkKeyName(chainId);
  const subscriptionContract = new Contract(
    CONTRACTS.TOKENS.BOBA[chainKeyName].address,
    CONTRACTS.TOKENS.BOBA[chainKeyName].abi,
    provider
  );
  return subscriptionContract;
};

const getBobaTuringCreditContract = (chainId, provider) => {
  const chainKeyName = chainIdToNetworkKeyName(chainId);
  const bobaTuringCreditContract = new Contract(
    CONTRACTS.BobaTuringCredit[chainKeyName].address,
    CONTRACTS.BobaTuringCredit[chainKeyName].abi,
    provider
  );
  return bobaTuringCreditContract;
};

export const loadTuringPrice = createAsyncThunk(
  'subscription/loadTuringPrice',
  async ({ provider, chainId }) => {
    const bobaTuringCreditContract = getBobaTuringCreditContract(
      chainId,
      provider
    );
    const turingPrice = await bobaTuringCreditContract.turingPrice();
    return turingPrice.toString();
  }
);

export const createSubscription = createAsyncThunk(
  'subscription/createSubscription',
  async ({ provider, chainId, dispatch, txId, onDoneCallback }) => {
    const subscriptionContract = getSubscriptionContract(chainId, provider);

    await executeTransaction({
      transactionCall: () => subscriptionContract.createSubscription(),
      txId: txId,
      onSucceededMsg: 'Subscription Created.',
      onDoneCallback: onDoneCallback,
      dispatch: dispatch,
    });
  }
);

export const loadOwnedSubscriptions = createAsyncThunk(
  'subscription/loadOwnedSubscription',
  async ({ provider, chainId, account, dispatch }) => {
    const subscriptionContract = getSubscriptionContract(chainId, provider);
    const count = await subscriptionContract.ownedSubscriptionCount(account);

    const ownedSubscriptionIdList = await Promise.all(
      Array.from(Array(count.toNumber()).keys()).map(async idx => {
        return (
          await subscriptionContract.ownedSubscriptionByIndex(account, idx)
        ).toNumber();
      })
    );

    ownedSubscriptionIdList.forEach(id => {
      dispatch(
        loadSubscriptionInfo({
          provider: provider,
          chainId: chainId,
          subscriptionId: id,
        })
      );
    });

    return {
      ownedSubscriptionIdList: ownedSubscriptionIdList,
    };
  }
);

export const cancelSubscription = createAsyncThunk(
  'subscription/cancelSubscription',
  async ({ provider, chainId, subscriptionId, dispatch, txId }) => {
    const subscriptionContract = getSubscriptionContract(chainId, provider);

    await executeTransaction({
      transactionCall: () =>
        subscriptionContract.cancelSubscriptionId(subscriptionId),
      txId: txId,
      onSucceededMsg: 'Subscription Cancelled.',
      onDoneCallback: () => {},
      dispatch: dispatch,
    });
  }
);

export const addOwner = createAsyncThunk(
  'subscription/addOwner',
  async ({
    provider,
    chainId,
    dispatch,
    subscriptionId,
    ownerAddress,
    txId,
    onDoneCallback,
  }) => {
    const subscriptionContract = getSubscriptionContract(chainId, provider);
    await executeTransaction({
      transactionCall: () =>
        subscriptionContract.addSubscriptionOnwer(subscriptionId, ownerAddress),
      txId: txId,
      onSucceededMsg: 'Owner added.',
      onDoneCallback: onDoneCallback,
      dispatch: dispatch,
    });
  }
);

export const removeOwner = createAsyncThunk(
  'subscription/removeOwner',
  async ({
    provider,
    chainId,
    dispatch,
    subscriptionId,
    ownerAddress,
    txId,
    onDoneCallback,
  }) => {
    const subscriptionContract = getSubscriptionContract(chainId, provider);
    await executeTransaction({
      transactionCall: () =>
        subscriptionContract.removeSubscriptionOnwer(
          subscriptionId,
          ownerAddress
        ),
      txId: txId,
      onSucceededMsg: 'Owner removed.',
      onDoneCallback: onDoneCallback,
      dispatch: dispatch,
    });
  }
);

export const addConsumer = createAsyncThunk(
  'subscription/addConsumer',
  async ({
    provider,
    chainId,
    dispatch,
    subscriptionId,
    consumerAddress,
    txId,
    onDoneCallback,
  }) => {
    const subscriptionContract = getSubscriptionContract(chainId, provider);
    await executeTransaction({
      transactionCall: () =>
        subscriptionContract.addPermittedCaller(
          subscriptionId,
          consumerAddress
        ),
      txId: txId,
      onSucceededMsg: 'Consumer added.',
      onDoneCallback: onDoneCallback,
      dispatch: dispatch,
    });
  }
);

export const removeConsumer = createAsyncThunk(
  'subscription/removeConsumer',
  async ({
    provider,
    chainId,
    dispatch,
    subscriptionId,
    consumerAddress,
    txId,
    onDoneCallback,
  }) => {
    const subscriptionContract = getSubscriptionContract(chainId, provider);
    await executeTransaction({
      transactionCall: () =>
        subscriptionContract.removePermittedCaller(
          subscriptionId,
          consumerAddress
        ),
      txId: txId,
      onSucceededMsg: 'Consumer removed.',
      onDoneCallback: onDoneCallback,
      dispatch: dispatch,
    });
  }
);

export const loadSubscriptionInfo = createAsyncThunk(
  'subscription/loadSubscriptionInfo',
  async ({ provider, chainId, subscriptionId }) => {
    const subscriptionContract = getSubscriptionContract(chainId, provider);
    const consumerCount = (
      await subscriptionContract.subscriptionPermittedCallerCount(
        subscriptionId
      )
    ).toNumber();

    const consumerList = await Promise.all(
      Array.from(Array(consumerCount).keys()).map(async idx => {
        const consumerAddress =
          await subscriptionContract.subscriptionPermittedCallerByIndex(
            subscriptionId,
            idx
          );
        return consumerAddress;
      })
    );

    const ownerCount = (
      await subscriptionContract.subscriptionOwnerCount(subscriptionId)
    ).toNumber();
    const ownerList = await Promise.all(
      Array.from(Array(ownerCount).keys()).map(async idx => {
        const ownerAddress =
          await subscriptionContract.subscriptionOwnerByIndex(
            subscriptionId,
            idx
          );
        return ownerAddress;
      })
    );

    const helperAddress =
      await subscriptionContract.getSubscriptionTuringHelper(subscriptionId);

    const bobaTuringCreditContract = getBobaTuringCreditContract(
      chainId,
      provider
    );
    const turingCredit = await bobaTuringCreditContract.getCreditAmount(
      helperAddress
    );

    return {
      subscriptionId: subscriptionId,
      consumerList: consumerList,
      ownerList: ownerList,
      helperAddress: helperAddress,
      credit: turingCredit.toString(),
    };
  }
);

export const loadBobaAllowance = createAsyncThunk(
  'subscription/loadBobaAllowance',
  async ({ provider, chainId, account }) => {
    const chainKeyName = chainIdToNetworkKeyName(chainId);
    const bobaContract = getBobaContract(chainId, provider);
    const subscriptionContractAddress =
      CONTRACTS.TuringSubscriptionManager[chainKeyName].address;
    const allowance = await bobaContract.allowance(
      account,
      subscriptionContractAddress
    );
    return allowance.toString();
  }
);

export const setApprovalForBoba = createAsyncThunk(
  'subscription/setApprovalForBoba',
  async ({ provider, chainId, enabled, txId, dispatch, onDoneCallback }) => {
    const chainKeyName = chainIdToNetworkKeyName(chainId);
    const bobaContract = getBobaContract(chainId, provider);
    const subscriptionContractAddress =
      CONTRACTS.TuringSubscriptionManager[chainKeyName].address;
    const approveAmount = enabled
      ? BigNumber.from('2').pow(256).sub(1)
      : constants.Zero;

    await executeTransaction({
      transactionCall: () =>
        bobaContract.approve(subscriptionContractAddress, approveAmount),
      txId: txId,
      onSucceededMsg: enabled ? 'Approved' : 'Unapproved',
      onDoneCallback: onDoneCallback,
      dispatch: dispatch,
    });
  }
);

export const addBalance = createAsyncThunk(
  'subscription/addBalance',
  async ({
    provider,
    chainId,
    subscriptionId,
    bobaAmount,
    dispatch,
    txId,
    onDoneCallback,
  }) => {
    const subscriptionContract = getSubscriptionContract(chainId, provider);

    await executeTransaction({
      transactionCall: () =>
        subscriptionContract.addBalanceToSubscription(
          subscriptionId,
          bobaAmount
        ),
      txId: txId,
      onSucceededMsg: 'Consumer removed.',
      onDoneCallback: onDoneCallback,
      dispatch: dispatch,
    });
  }
);

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState: {
    isLoading: false,
    turingPrice: constants.Zero.toString(),
    ownedSubscriptionIdList: [],
    subscriptionInfoMap: [],
    allowance: constants.Zero.toString(),
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(loadOwnedSubscriptions.pending, state => {
        state.isLoading = true;
      })
      .addCase(loadOwnedSubscriptions.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.ownedSubscriptionIdList =
            action.payload.ownedSubscriptionIdList;
        }
        return state;
      })
      .addCase(loadOwnedSubscriptions.rejected, state => {
        state.isLoading = false;
      })
      .addCase(loadTuringPrice.fulfilled, (state, action) => {
        if (action.payload) {
          state.turingPrice = action.payload;
        }
        return state;
      })
      .addCase(loadSubscriptionInfo.pending, state => {
        state.isLoading = true;
      })
      .addCase(loadSubscriptionInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          const subscriptionInfo = action.payload;
          state.subscriptionInfoMap[subscriptionInfo.subscriptionId] =
            subscriptionInfo;
        }
        return state;
      })
      .addCase(loadSubscriptionInfo.rejected, state => {
        state.isLoading = false;
      })
      .addCase(loadBobaAllowance.pending, state => {
        state.isLoading = true;
        return state;
      })
      .addCase(loadBobaAllowance.fulfilled, (state, action) => {
        if (action.payload) {
          state.allowance = action.payload;
        }
        return state;
      })
      .addCase(loadBobaAllowance.rejected, state => {
        state.isLoading = false;
      });
  },
});

const subscriptionState = state => state.subscription;

export default subscriptionSlice.reducer;
export const getSubscriptionState = createSelector(
  subscriptionState,
  subscription => subscription
);
