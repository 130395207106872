export const NETWORKS = {
  boba: {
    keyName: 'boba',
    chainId: `0x${Number(288).toString(16)}`,
    chainName: 'Boba Network',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://mainnet.boba.network'],
    blockExplorerUrls: ['https://blockexplorer.boba.network/'],
  },
  bobarinkeby: {
    keyName: 'bobarinkeby',
    chainId: `0x${Number(28).toString(16)}`,
    chainName: 'Boba Rinkeby Testnet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://rinkeby.boba.network/'],
    blockExplorerUrls: ['https://blockexplorer.rinkeby.boba.network/'],
  },
};

export const DEFAULT_CHAIN_ID =
  process.env.REACT_APP_DEFAULT_CHAIN_ID || NETWORKS.boba.chainId;
