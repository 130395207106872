import {
  VStack,
  Heading,
  Box,
  HStack,
  Button,
} from '@chakra-ui/react';

import { NETWORKS } from '../constants/Networks';
import { switchNetwork } from '../utils';

export const UnsupportedNetworkMessage = () => {
  return (
    <VStack
      width="100%"
      sx={{
        border: '1px solid rgb(240, 46, 170 )',
        borderRadius: '10px',
        padding: '20px',
        boxShadow:
          'rgba(240, 46, 170, 0.4) 0px 5px, rgba(240, 46, 170, 0.3) 0px 10px, rgba(240, 46, 170, 0.2) 0px 15px, rgba(240, 46, 170, 0.1) 0px 20px, rgba(240, 46, 170, 0.05) 0px 25px;',
      }}
    >
      <Heading
        fontSize="2xl"
        sx={{
          fontFamily: '"Press Start 2P"',
        }}
      >
        Unsupported Network
      </Heading>
      <Box>
        You connected to an unsupported network. Please connect to a supported
        network.
      </Box>
      <HStack>
        <Button onClick={() => switchNetwork(NETWORKS.boba.keyName)}>Boba Network</Button>
        <Button onClick={() => switchNetwork(NETWORKS.bobarinkeby.keyName)}>Boba Rinkeby Testnet</Button>
      </HStack>
    </VStack>
  );
};