import { Box, HStack, Text } from '@chakra-ui/layout';
import { useWeb3React } from '@web3-react/core';
import { InjectedConnector } from '@web3-react/injected-connector';
import { useEffect } from 'react';
import { NETWORKS } from '../constants/Networks';
import { getAddressAbbr } from '../utils';
import { Blockie } from './Blockie';

const injectedConnector = new InjectedConnector({
  supportedChainIds: Object.keys(NETWORKS).map(chain =>
    parseInt(NETWORKS[chain].chainId, 16)
  ),
});

export const Wallet = () => {
  const { active, account, activate, deactivate } = useWeb3React();
  const connectButton = <Text>Connect</Text>;
  const disconnectButton = (
    <HStack>
      <Blockie address={account} style={{ maxHeight: '25px' }} />
      <Text>{account ? getAddressAbbr(account) : ''}</Text>
    </HStack>
  );

  useEffect(() => {
    // Connect wallet when refresh if metamask is authorized.
    const isAuthed = injectedConnector.isAuthorized();
    if (isAuthed) {
      activate(injectedConnector);
    }
  }, [activate]);

  const connect = async () => {
    try {
      await activate(injectedConnector);
      console.log('Wallet connected.');
    } catch (e) {
      console.log(e.message);
    }
  };

  const disconnect = async () => {
    try {
      deactivate();
      console.log('Wallet disconnected.');
    } catch (e) {
      console.log(e.message);
    }
  };
  return (
    <Box
      sx={{
        padding: '5px 10px',
        borderRadius: '5px',
        border: '1px solid rgb(240, 46, 170)',
        cursor: 'pointer',
        _hover: {
          background: 'rgb(240, 46, 170)',
        },
      }}
      onClick={() => {
        if (active) {
          disconnect();
        } else {
          connect();
        }
      }}
    >
      {!active ? connectButton : disconnectButton}
    </Box>
  );
};
